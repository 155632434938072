<template>
  <div>
    <v-navigation-drawer 
      v-if="currentUser"
      v-model="drawer"
      :mobile-breakpoint="762"
      absolute
      temporary
      color="primary"
      >
      <v-list
        class="fill-height"
        dense
        nav
        >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              Menú
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          class="mt-3 primary lighten-4 rounded-lg"
          :value="true"
          >
          <template v-slot:activator>
            <v-list-item
              class="align-center px-0"
              >
              <v-list-item-icon>
                <img
                  src="@/assets/icons/tiantar-rollout.svg"
                  height="24px"
                  width="24px"
                  ></img>
              </v-list-item-icon>
              <v-list-item-title>Rollout</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item
            link
            :to="{ name: 'rollout', params: { id: $route.params.id } }"
            >
            <v-list-item-title>Detalle</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: 'rollouts', params: { id: $route.params.id } }"
            >
            <v-list-item-title>General</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          class="mt-3 primary lighten-4 rounded-lg"
          link
          v-if="(['shibui', 'management', 'finance'].includes(currentUser.currentRoleName))"
          :to="{ name: 'importers', params: { id: $route.params.id } }"
          >
          <v-list-item-icon>
            <img
              src="@/assets/icons/tiantar-batch-import.png"
              height="24px"
              width="24px"
              ></img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Carga masiva</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3 primary lighten-4 rounded-lg"
          link
          :to="{ name: 'dashboards', params: { id: $route.params.id } }"
          >
          <v-list-item-icon>
            <img
              src="@/assets/icons/tiantar-dashboard.svg"
              height="24px"
              width="24px"
              ></img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboards</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3 primary lighten-4 rounded-lg"
          link
          :to="{ name: 'reports', params: { id: $route.params.id } }"
          >
          <v-list-item-icon>
            <img
              src="@/assets/icons/tiantar-reports.svg"
              height="24px"
              width="24px"
              ></img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Reportes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-3 primary lighten-4 rounded-lg"
          link
          :to="{ name: 'repository', params: { id: $route.params.id } }"
          >
          <v-list-item-icon>
            <img
              src="@/assets/icons/tiantar-repository.svg"
              height="24px"
              width="24px"
              ></img>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Repositorio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          class="mt-3 primary lighten-4 rounded-lg"
          v-if="currentUser"
          >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-office-building-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ currentUser.selectedAgency.name }}</v-list-item-title>
          </template>

          <v-list-item
            link
            @click="changeSelectedOrganisation(organisation.id)"
            v-if="!organisation.selected"
            v-for="organisation in currentUser.userAgencies"
            >
            <v-list-item-content>
              <v-list-item-title>{{ organisation.agency.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-list
          nav
          dense
          color="primary lighten-4"
          v-if="currentUser"
          >
          <v-list-item>
            <v-list-item-title
              class="text-subtitle-2 font-weight-regular"
              >
              <span class="font-weight-medium">{{ currentUser.email }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-divider
            class="my-1"
            ></v-divider>
          <v-list-item
            @click="logOut"
            >
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TokenService } from '@/services/storageService'
import { SwitchSelectedOrganisation } from '@/graphql/mutations/users'

export default {
  data () {
    return {
      drawer: false
    }
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  methods: {
    logOut () {
      TokenService.removeToken()
      this.$router.go('')
    },

    changeSelectedOrganisation (id) {
      this.$apollo.mutate({
        mutation: SwitchSelectedOrganisation,
        variables: {
          input: {
            id: id
          }
        }
      }).then( res => {
        this.$router.go()
      })
    }
  },
}
</script>
