import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';

import tiantarBack from '@/assets/icons/tiantar-back.svg'

Vue.use(Vuetify)

const CUSTOM_ICONS = {
  tiantarBack: tiantarBack,
}

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#1A57FF', // #E53935
        secondary: '#EEE'
      },
    },
  },
  icons: {
    values: CUSTOM_ICONS
  },
}

export default new Vuetify(opts)
