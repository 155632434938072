<template>
  <div>
    <detail
      ref="detail"
      :id="notification.notificableId"
      :notificationId="notification.id"
      @reload="$emit('reload')"
      ></detail>

    <v-card
      tile
      outlined
      @click="openDetail"
      style="border-left: 0px; border-right: 0px; margin-bottom: -1px"
      >
      <v-card-text
        class="text--primary"
        >
        <div
          class="d-flex justify-space-between align-center"
          >
          <div>
            {{ notification.text }}
          </div>

          <div
            class="text-right caption"
            >
            hace {{ transformTime(notification.elapsed) }}
          </div>
        </div>

        <div
          class="mt-2"
          >
          Tarea {{ notification.rollout.id }} - Sitio {{ notification.rollout.site.siteId }} - {{ notification.rollout.project.name }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Detail from './Detail'

export default {
  props: {
    notification: {
      required: true,
      type: Object
    }
  },

  methods: {
    transformTime (seconds) {
      if (seconds < 60) {
        if (seconds == 1) return seconds + ' segundo'
        return seconds + ' segundos'
      } else if (seconds >= 60) {
        var min = Math.floor(seconds / 60)
        if (min == 1) return min + ' minuto'
        if (min < 60) return min + ' minutos'

        var hour = Math.floor(min / 60)
        if (hour == 1) return hour + ' hora'
        if (hour < 24) return hour + ' horas'

        var days = Math.floor(hour / 24)
        if (days == 1) return days + ' día'
        return days + ' días'
      }
    },

    openDetail () {
      this.$refs.detail.dialog = true
    }
  },

  components: {
    Detail
  }
}
</script>
