import gql from 'graphql-tag'

export const Messages = gql`
  query messages($notificationId: ID!) {
    messages(notificationId: $notificationId) {
      id
      body
      createdAt

      sender {
        id
        email
      }
    }
  }
`
