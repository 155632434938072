<template>
  <v-menu
    bottom
    right
    offset-y
    :close-on-content-click="false"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        depressed
        fab
        class="rounded-lg primary lighten-4"
        v-on="on"
        v-bind="attrs"
        >
        <v-icon
          large
          color="primary"
          >
          mdi-bell
        </v-icon>
      </v-btn>
    </template>

    <v-card
      style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
      class="fill-height rounded-lg"
      width="500"
      >
      <v-card-title
        style="gap: 8px"
        class="body-2 grey lighten-4"
        >
        <div
          style="position: relative"
          >
          <v-icon class="mt-n1" size="24" color="primary">mdi-bell</v-icon> 

          <div
            style="width: 8px; height: 8px; position: absolute; top: 0px; right: 2px"
            class="red rounded-circle"
            ></div>
        </div>

        <span class="font-weight-bold">{{ notifications.length }}</span> Notificaciones recibidas
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text
        class="px-0"
        >
        <template
          v-for="notification in notifications"
          v-if="notifications.length"
          >
          <notification
            :notification="notification"
            ></notification>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { Notifications } from '@/graphql/queries/notifications'

import Notification from '@/components/notifications/Notification'

export default {
  data: () => ({
    notifications: []
  }),

  created () {
    this.fetchNotifications ()
  },

  methods: {
    fetchNotifications () {
      this.$apollo.query({
        query: Notifications,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.notifications = res.data.notifications
      })
    }
  },

  components: {
    Notification
  }
}
</script>
