import gql from 'graphql-tag'
import { Dispatch as DispatchFragment } from '@/graphql/fragments'

export const Dispatch = gql`
  query dispatch($id: ID!) {
    dispatch(id: $id) {
      ...${ DispatchFragment }
    }
  }
`
