import gql from 'graphql-tag'

const Movement = `
  {
    id
    rolloutId
    originRolloutId
    approved

    destination {
      id
      name
    }

    origin {
      id
      name
    }

    stock {
      id
      serialNumber
      obs
      condition
      item {
        id
        sku
        description
      }
      stockLocation {
        id
        name
      }
    }
  }
`

const Dispatch = `
  {
    id
    originType
    originId
    rolloutId
    movementType
    elapsed
    stockLocationId
    source {
      id
      name
    }
    stockLocation {
      id
      name
    }
    movements {
      ...${ Movement }
    }
  }
`

export { Movement, Dispatch }
