<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    scrollable
    >
    <v-card
      rounded="lg"
      >
      <v-card-title>
        Detalle de notificación
      </v-card-title>

      <v-card-text
        v-if="dispatch"
        >
        <v-tabs vertical>
          <v-tab>
            <v-icon left>
              mdi-package
            </v-icon>
            Movimientos
          </v-tab>
          <v-tab
            class="justify-start"
            >
            <v-icon left>
              mdi-message
            </v-icon>
            Mensajes
          </v-tab>

          <v-tab-item>
            <dispatch-detail
              :dispatch="dispatch"
              @reload="reload"
              ></dispatch-detail>
          </v-tab-item>

          <v-tab-item>
            <messages
              :id="notificationId"
              ></messages>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Dispatch } from '@/graphql/queries/dispatches'
import DispatchDetail from './Dispatch'
import Messages from './Messages'

export default {
  data: () => ({
    dialog: false,
    dispatch: null
  }),

  props: {
    id: {
      type: Number | String,
      required: true
    },

    notificationId: {
      type: Number | String,
      required: true
    }
  },

  watch: {
    dialog (val) {
      if (val) {
        this.fetchDispatch()
      }
    }
  },

  methods: {
    fetchDispatch () {
      this.$apollo.query({
        query: Dispatch,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.dispatch = res.data.dispatch
      })
    },

    reload () {
      this.fetchDispatch()
      this.$emit('reload')
    }
  },

  components: {
    DispatchDetail,
    Messages
  }
}
</script>
