import gql from 'graphql-tag'

export const Notifications = gql`
  query notifications {
    notifications {
      id
      elapsed
      text
      notificableId
      notificableType

      rollout {
        id
        project {
          id
          name
        }
        site {
          id
          siteId
        }
      }
    }
  }
`
