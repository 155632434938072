<template>
  <v-app>
    <div
      class="background"
      ></div>
    <app-bar
      v-if="$route.meta.appbar"
      :project="project"
      ></app-bar>
    <v-main>
      <div
        class="mx-auto fill-height"
        >
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { SignIn } from '@/graphql/mutations/users'
import { Project } from '@/graphql/queries/projects'
import { mapGetters } from 'vuex'

import AppBar from '@/components/bars/AppBar'

export default {
  data: () => ({
    project: null
  }),

  created () {
    if (!this.currentUser && this.isLoggedIn) {
      this.signIn()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn'])
  },

  watch: {
    "$route.params.id": {
      handler (val) {
        if (val) {
          this.fetchProject()
        }
      }
    }
  },

  methods: {
    signIn () {
      this.$apollo.mutate({
        mutation: SignIn,
        variables: {
          input: {
            token: localStorage.getItem('auth_token')
          }
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        let data = res.data.signIn

        if (data.token) {
          localStorage.setItem('auth_token', data.token)
          this.$store.commit("setCurrentUser", data.user)
        } else {
          localStorage.removeItem('auth_token')
        }
      }).catch(errors => {
        localStorage.removeItem('auth_token')
        this.$router.go()
      })
    },

    fetchProject () {
      this.$apollo.query({
        query: Project,
        variables: {
          id: this.$route.params.id
        }
      }).then ( res => {
        this.project = res.data.project

        this.$store.commit('setProject', res.data.project)
      })
    }
  },

  components: { AppBar }
}
</script>
