import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem("auth_token"),
    currentUser: null,
    url: process.env.VUE_APP_RAILS_URL,
    project: null,
  },
  mutations: {
    login(state) {
      state.isLoggedIn = true
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setProject (state, project) {
      state.project = project;
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    currentUser: state => {
      return state.currentUser
    },
    url: state => {
      return state.url
    },
    project: state => {
      return state.project
    }
  },
  actions: {
  },
})
