import gql from 'graphql-tag'

export const Projects = gql`
  query projects {
    projects {
      id
      name
      slug
      projectType
      operator {
        id
        name
      }
    }
  }
`

export const Project = gql`
  query project($id: ID!) {
    project(id: $id) {
      id
      name
      slug
      projectType
      operator {
        id
        name
      }
    }
  }
`
