<template>
  <div>
    <v-app-bar
      dense
      app
      flat
      color="#002eac"
      >
      <v-toolbar-title
        class="fill-width"
        >
        <div
          class="d-flex justify-space-between fill-width"
          >
          <div
            >
            <div
              class="d-flex align-center"
              v-if="$route.params.id"
              >
              <v-btn
                small
                depressed
                fab
                class="rounded-lg primary lighten-4"
                @click="$refs.nav.drawer = !$refs.nav.drawer"
                >
                <v-icon
                  large
                  >
                  mdi-apps
                </v-icon>
              </v-btn>

              <div
                class="ml-3 white--text font-weight-medium"
                v-if="project"
                >
                {{ project.name }} - {{ project.operator.name }}
              </div>
            </div>
          </div>

          <div
            style="gap: 12px"
            class="d-flex"
            >
            <notifications
              ></notifications>

            <v-btn
              small
              depressed
              fab
              class="rounded-lg primary lighten-4"
              :to="{ name: 'home' }"
              >
              <v-icon
                large
                color="primary"
                >
                mdi-home
              </v-icon>
            </v-btn>

            <v-btn
              small
              depressed
              fab
              class="rounded-lg primary lighten-4"
              :to="{ name: 'admin' }"
              >
              <img
                src="@/assets/icons/tiantar-settings.svg"
                height="36px"
                width="36px"
                ></img>
            </v-btn>

            <v-btn
              small
              depressed
              fab
              class="rounded-lg primary lighten-4"
              >
              <img
                src="@/assets/icons/tiantar-help.svg"
                height="36px"
                width="36px"
                ></img>
            </v-btn>
          </div>
        </div>
      </v-toolbar-title>
    </v-app-bar>

    <nav-bar
      ref="nav"
      ></nav-bar>
  </div>
</template>

<script>
import NavBar from './Navbar'
import Notifications from '@/components/notifications/Notifications'

export default {
  props: {
    project: {
      required: false,
      type: Object
    }
  },

  components: {
    NavBar,
    Notifications
  }
}
</script>
