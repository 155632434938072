import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Settings.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/rollout',
    name: 'rollout',
    component: () => import(/* webpackChunkName: "rollout" */ '@/views/Rollout.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/rollouts',
    name: 'rollouts',
    component: () => import(/* webpackChunkName: "rollout" */ '@/views/Rollouts.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/repository',
    name: 'repository',
    component: () => import(/* webpackChunkName: "repository" */ '@/views/Repository.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/importers',
    name: 'importers',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Importers.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/project/:id/dashboards',
    name: 'dashboards',
    component: () => import(/* webpackChunkName: "dashboards" */ '@/views/Dashboards.vue'),
    meta: {
      requireLogin: true,
      appbar: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue'),
    meta: {
      hideAppbar: true,
      hideBottom: true,
      onlyWhenLoggedOut: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireLogin = to.matched.some(record => record.meta.requireLogin)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!localStorage.getItem('auth_token');

  if (requireLogin && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next();
})

export default router
