//This document uses camelcase to denominate all variables, if a category(object) has multiple of the same thing, it goes in the plural form
//open-ended phrases must have a space at the end.
//The only exception to this rules is that I couldn't stop you
//so you could do what the fuck you want
// 🙈

export default {
  actions: {
    login: 'Iniciar sesión'
  },
  errors: {
    password_did_not_match: 'Contraseña incorrecta',
    user_not_found: 'Usuario no encontrado',
    approval_code_has_already_been_taken: 'Código de aprobación ya se encuentra en uso',
    bill_number_has_already_been_taken: 'Número de factura ya se encuentra en uso',
    number_has_already_been_taken: 'Número de factura ya se encuentra en uso',
    site_id_has_already_been_taken: 'Site ID ya se encuentra en uso',
    name_has_already_been_taken: 'Nombre de sitio ya se encuentra en uso',
  },
  fields: {
    site: {
      siteId: "Site ID", 
      name: "Nombre", 
      address: "Dirección", 
      height: "Altura", 
      lng: "Longitud", 
      lat: "Latitud", 
      region: "Región", 
      chief: "Jefe Zonal", 
      neighbourhood: "Comuna", 
      structureType: "Tipo de Estructura",
      structureCode: "Cód. de Estructura",
      towerOwner: "Dueño Torre",
      obs: "Observaciones",
    },
    rollout: {
      createdAt: 'Fecha de creación',
      assignationDate: 'Fecha de asignación',
      startAt: 'Fecha de inicio',
      endsAt: 'Fecha de fin',
      documentationSentAt: 'Fecha de envío doc',
      dpr: 'DPR',
      obs: 'Observaciones',
      workType: 'Tipo de trabajo',
    },
    rollouts: {
      id: 'ID tarea',
      createdAt: 'Fecha de creación',
      assignationDate: 'Fecha de asignación',
      startAt: 'Fecha de inicio',
      endsAt: 'Fecha de fin',
      documentationSentAt: 'Fecha de envío doc',
      dpr: 'DPR',
      obs: 'Observaciones',
      workType: 'Tipo de trabajo',
      siteId: "Site ID", 
      name: "Nombre", 
      address: "Dirección", 
      height: "Altura", 
      lng: "Longitud", 
      lat: "Latitud", 
      region: "Región", 
      chief: "Jefe Zonal", 
      neighbourhood: "Comuna", 
      structureType: "Tipo de Estructura",
      structureCode: "Cód. de Estructura",
      towerOwner: "Dueño Torre",
      obs: "Observaciones",
      technology: "Tecnología",
      additionals: "Contiene adicionales",
      activities: "Contiene actividades",
      validatedActivities: "Actividades validadas",
      validatedAdditionals: "Adicionales validados",
    },
  },
  status: {
    rollout: {
      assigned: 'Asignado',
      installing: 'Instalación',
      installed: 'Instalado',
      pre_approved: 'Doc. enviada',
      approved: 'Aprobado',
      cancelled: 'Cancelado',
      partial: 'Enviado Fac. parcial',
      partial_billed: 'Fac. parcial',
      total: 'Enviado Fac. total',
      closed: 'Cerrado',
      undefined: '',
    }
  },
  workTypes: {
    implementation: 'Implementación',
    TSS: 'TSS'
  }
}
