<template>
  <v-card 
    outlined
    tile
    >
    <v-card-text>
      <div
        >
        <div
          v-if="messages.length"
          class="d-flex flex-column-reverse"
          style="max-height: 500px; overflow: auto; gap: 12px"
          >
          <template
            v-for="message in messages"
            >
            <v-card
              flat
              color="grey lighten-4"
              class="rounded-lg"
              >
              <v-card-title
                class="justify-end py-1 mb-1"
                style="border-bottom: 1px solid #dedede"
                >
                <div
                  class="text-right d-flex flex-row caption"
                  style="gap: 8px" 
                  >
                  <div
                    class="font-weight-medium"
                    >
                    {{ message.sender.email }}
                  </div>

                  <div>
                    -
                  </div>

                  <div
                    class="caption"
                    >
                    {{ new Date(message.createdAt).toLocaleDateString() }}
                  </div>
                </div>
              </v-card-title>
              <v-card-text
                class="text--primary"
                >
                {{ message.body }}
              </v-card-text>
            </v-card>
          </template>
        </div>

        <v-card
          flat
          color="grey lighten-4"
          v-else
          class="rounded-lg"
          >
          <v-card-text
            class="text--primary"
            >
            Aún no hay mensajes aquí.
          </v-card-text>
        </v-card>
      </div>

      <div
        class="mt-6"
        >
        <v-textarea
          outlined
          label="Nuevo mensaje"
          placeholder="Escribir mensajes..."
          rows="3"
          v-model="newMessage"
          class="rounded-lg"
          >
          <template v-slot:append>
            <v-btn
              fab
              small
              depressed
              :disabled="!newMessage"
              color="primary"
              @click="send"
              >
              <v-icon
                small
                >
                mdi-send
              </v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { CreateMessage } from '@/graphql/mutations/messages'
import { Messages } from '@/graphql/queries/messages'

export default {
  data: () => ({
    messages: [],
    newMessage: null
  }),

  props: {
    id: {
      type: Number | String,
      required: true
    }
  },

  created () {
    this.fetchMessages ()
  },

  methods: {
    fetchMessages () {
      this.$apollo.query({
        query: Messages,
        variables: {
          notificationId: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.messages = res.data.messages
      })
    },

    send () {
      this.$apollo.mutate({
        mutation: CreateMessage,
        variables: {
          input: {
            body: this.newMessage,
            notificationId: this.id
          }
        }
      }).then ( res => {
        this.newMessage = null
        this.fetchMessages()
      })
    }
  }
}
</script>
