import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import VueI18n from 'vue-i18n'
import messages from './i18n'
import "@/style/application.sass"

import { apolloProvider } from './graphql/apollo'

Vue.config.productionTip = false

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  silentFallbackWarn: true,
  messages
})


new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
