import gql from 'graphql-tag'
import { Store } from '@/graphql/fragments'

export const EditUser = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      clientMutationId
      success
    }
  }
`

export const UpdatePassword = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      clientMutationId
      success
    }
  }
`

export const CreateUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
    }
  }
`

export const SignIn = gql`
  mutation signIn($input: SignInUserInput!) {
    signIn(input: $input) {
      clientMutationId
      token
      user {
        id
        email
        currentRoleName
        selectedAgency {
          id
          name
        }
        userAgencies {
          id
          selected
          agency {
            name
          }
        }
      }
    }
  }
`

export const SignoutUser = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      success
    }
  }
`

export const AddUser = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      clientMutationId
      success
    }
  }
`

export const EditOrganisationUser = gql`
  mutation editOrganisationUser($input: EditOrganisationUserInput!) {
    editOrganisationUser(input: $input) {
      success
    }
  }
`

export const RemoveUser = gql`                                                   
  mutation removeUserFromOrganisation($input: RemoveUserFromOrganisationInput!) {
    removeUserFromOrganisation(input: $input) {                                  
      success                                                                    
    }                                                                            
  }                                                                              
`                                                                                

export const SwitchSelectedOrganisation = gql`
  mutation switchSelectedOrganisation($input: SwitchSelectedOrganisationInput!) {
    switchSelectedOrganisation(input: $input) {
      success
    }
  }
`
